import { ButtonHTMLAttributes, DetailedHTMLProps, useCallback } from 'react';
import { Spinner } from '../images/Spinner';
import classnames from 'classnames';
import { logEvent } from 'firebase/analytics';
import { useFirebase } from './FirebaseContext';

type ButtonType = 'primary' | 'secondary' | 'danger' | 'success' | 'warning';

interface Props
  extends Omit<
    DetailedHTMLProps<
      ButtonHTMLAttributes<HTMLButtonElement>,
      HTMLButtonElement
    >,
    'type'
  > {
  isLoading?: boolean;
  type?: ButtonType;
  analyticsEvent?: string;
  analyticsData?: Record<string, string>;
}

function getColours(
  type: ButtonType = 'primary',
  disabled: boolean = false,
  isLoading: boolean = false
) {
  switch (type) {
    case 'primary':
      return {
        'hover:bg-cc-100 bg-cc-200': !disabled && !isLoading,
        'border-white': !disabled,
        'border-cc-200 text-cc-100': disabled,
      };
    case 'secondary':
      return {
        'hover:bg-cc-200': !disabled && !isLoading,
        'border-white': !disabled,
        'border-cc-200': disabled,
        'text-cc-100': disabled,
      };
    case 'danger':
      return {
        'bg-rose-500 hover:bg-rose-400': !disabled && !isLoading,
        'bg-rose-600 text-white border-rose-400': !disabled,
        'bg-rose-900 text-rose-600 border-rose-600': disabled,
      };
    case 'success':
      return {
        'bg-emerald-500 hover:bg-emerald-400': !disabled && !isLoading,
        'bg-emerald-600 text-white border-emerald-400': !disabled,
        'bg-emerald-900 text-emerald-600 border-emerald-600': disabled,
      };
  }
}

export function Button({
  isLoading,
  children,
  disabled,
  analyticsEvent,
  analyticsData,
  ...props
}: Props) {
  const { type = 'primary', ...otherProps } = props;
  const colors = getColours(type, disabled, isLoading);

  const { analytics } = useFirebase();
  const { onClick } = props;
  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (analyticsEvent) {
        logEvent(analytics, analyticsEvent, analyticsData);
      }

      if (onClick) {
        onClick(event);
      }
    },
    [analytics, analyticsData, analyticsEvent, onClick]
  );

  return (
    <button
      {...otherProps}
      className={classnames(
        'flex w-full min-w-[100px] items-center justify-center rounded-sm border border-solid p-2 font-medium leading-4 transition-colors',
        {
          ...colors,
        }
      )}
      disabled={disabled || isLoading}
      onClick={handleClick}
    >
      {!isLoading && <span>{children}</span>}
      {isLoading && (
        <div className="h-4 w-4 stroke-white align-middle">
          <Spinner className="h-full w-full" />
        </div>
      )}
    </button>
  );
}
